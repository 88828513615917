import { useEffect, useState } from "react";
import BarChart from "./BarChart";

import {
  CHART_BG_COLORS,
  CHART_BORDER_COLORS,
  check_date_within_range,
} from "../../utils/reports";
import {
  IdentifiedObjectItem,
  IdentifiedSpeciesCount,
} from "../../models/Media";
import { CameraItem } from "../../models/Camera";

interface SpeciesByHourProps {
  cameras: CameraItem[];
  selectedCameras: CameraItem[];
  allIdentifiedObjects: IdentifiedObjectItem[];
  selectedIdentifiedObjects: IdentifiedObjectItem[];
  reportData: IdentifiedSpeciesCount[];
  startDate: string;
  endDate: string;
}

export const getTotalCount = (
  object_name: string,
  hourIndex: number,
  reportData: any[],
  cameras: CameraItem[],
  startDate: string,
  endDate: string
): number | null => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      for (const camera of cameras) {
        // Check if the date is within the selected range, if provided
        const isWithinDateRange = check_date_within_range(
          startDate,
          endDate,
          data.date
        );
        // Convert the date from the data to a JavaScript Date object
        const dataDate = new Date(data.date);
        const isWithinHour = dataDate.getUTCHours() === hourIndex;

        if (
          isWithinDateRange &&
          isWithinHour &&
          camera.camera_id === data.camera
        )
          total_count += data.count;
      }
    }
    return total_count > 0 ? total_count : null;
  }

  return 0;
};
export const getCount = (
  object_name: string,
  hourIndex: number,
  reportData: any[],
  camera: string,
  startDate: string,
  endDate: string
): number => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      // Check if the date is within the selected range, if provided
      const isWithinDateRange = check_date_within_range(
        startDate,
        endDate,
        data.date
      );
      // Convert the date from the data to a JavaScript Date object
      const dataDate = new Date(data.date);
      const isWithinHour = dataDate.getUTCHours() === hourIndex;

      if (isWithinDateRange && isWithinHour && camera === data.camera)
        total_count += data.count;
    }
    return total_count;
  }

  return 0;
};
const LocationSpeciesByHour = ({
  cameras,
  selectedCameras,
  allIdentifiedObjects,
  selectedIdentifiedObjects,
  reportData,
  startDate,
  endDate,
}: SpeciesByHourProps) => {
  const [mapData, setMapData] = useState<any>(null);
  const [csvData, setCsvData] = useState<any>();
  const ChartName = "Species Observed by Hour of the Day";

  const hourNames = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  // Create the customized formatted data for exporting CSV file
  const csvArrays: any = (hourIndex: number) =>
    selectedCameras?.flatMap((camera) => {
      return selectedIdentifiedObjects?.map((object) => {
        let regionObject: any = {
          camera: camera.camera_id,
          Object: object.name,
          hour: hourIndex,
          Count: getCount(
            object.name,
            hourIndex,
            reportData,
            camera.camera_id,
            startDate,
            endDate
          ),
        };
        return regionObject;
      });
    });

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    // Load all csv related data
    const csv = hourNames?.flatMap((_, hourIndex) => csvArrays(hourIndex));
    setCsvData(csv);
  }, [
    selectedCameras,
    selectedIdentifiedObjects,
    startDate,
    endDate,
    reportData,
  ]);

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    if (cameras && selectedIdentifiedObjects) {
      const data = {
        labels: hourNames,
        datasets:
          selectedIdentifiedObjects?.map((objectName, index) => ({
            label: objectName.name,
            data: hourNames?.map((_, hourIndex) =>
              getTotalCount(
                objectName.name,
                hourIndex,
                reportData,
                cameras,
                startDate,
                endDate
              )
            ),
            backgroundColor:
              CHART_BG_COLORS[Object.keys(CHART_BG_COLORS)[index]],
            borderColor:
              CHART_BORDER_COLORS[Object.keys(CHART_BORDER_COLORS)[index]],
            borderWidth: 1,
          })) ?? [],
      };
      setMapData(data);
    }
  }, [
    selectedCameras,
    selectedIdentifiedObjects,
    reportData,
    startDate,
    endDate,
  ]);

  const getYAxisConfig = (datasets: any[]) => {
    if (!datasets || datasets.length === 0) {
      return { suggestedMax: 10, stepSize: 1 };
    }

    // Extract all count values from datasets
    const allValues = datasets.flatMap((dataset) =>
      dataset.data.map((d: any) => (d !== null && d !== undefined ? d : 0))
    );

    const maxValue = Math.max(...allValues);

    let stepSize = 1;
    let suggestedMax = 10;

    if (maxValue < 10) {
      stepSize = 1;
      suggestedMax = 10;
    } else if (maxValue < 70) {
      stepSize = 10;
      suggestedMax = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 350) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else if (maxValue < 1000) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else {
      stepSize = 200; // Adjust as needed for larger max values
      suggestedMax = Math.ceil(maxValue / 200) * 200;
    }

    return { suggestedMax, stepSize };
  };

  // Using mapData conditionally to avoid error
  const { suggestedMax, stepSize } = mapData
    ? getYAxisConfig(mapData.datasets)
    : { suggestedMax: 10, stepSize: 1 };

  // Define chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Animals Observed by Time-of-Day",
        position: "top" as const,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: suggestedMax,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
  };

  return (
    <BarChart
      mapData={mapData}
      options={options}
      csvData={csvData}
      name={ChartName}
    />
  );
};

export default LocationSpeciesByHour;
