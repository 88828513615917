import React, { useEffect, useState } from "react";
import { getCameras } from "../services/Camera";
import { CameraItem } from "../models/Camera";
import Videos from "./Media";
import Spinner from "./Spinner";
import Select from "react-select";
import { useSelectedMediaContext } from "../context/MediaContext";

interface CameraProps {
  groupId?: number;
  onCamerasLoaded: (loadedCameras: CameraItem[]) => void;
}

const Cameras: React.FC<CameraProps> = ({ groupId, onCamerasLoaded }) => {
  const [cameraItems, setCameraItems] = useState<CameraItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<CameraItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { setSelectedMedia } = useSelectedMediaContext();

  const loadCameras = () => {
    setLoading(true);
    const getCameras$ = getCameras({ group: groupId, page: "all" });
    const sub = getCameras$.subscribe({
      next: (res) => {
        console.log("res from camera", res);
        const items = res?.map((item: CameraItem) => ({
          id: item.id,
          camera_id: item.camera_id,
        }));
        onCamerasLoaded(items);
        setCameraItems(items);
        if (items.length > 0) {
          setSelectedItems([items[0]]);
        }
        setLoading(false);
      },
      error: (error) => {
        console.error("Error loading cameras:", error);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadCameras();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  // Map cameraItems to react-select options
  const cameraOptions = cameraItems.map((item) => ({
    value: item.id,
    label: item.camera_id,
  }));

  // Handle changes in react-select
  const handleCameraSelectChange = (selectedOptions: any) => {
    const selectedCameras = selectedOptions
      ? cameraItems.filter((item) =>
          selectedOptions.some((option: any) => option.value === item.id)
        )
      : [];
    setSelectedItems(selectedCameras);
    setPage(1); // Reset page when new cameras are selected
  };

  useEffect(() => {
    // Reset selected media when no cameras are selected
    if (cameraItems.length === 0 || selectedItems.length === 0) {
      setSelectedMedia(null);
    }
  }, [cameraItems, selectedItems, setSelectedMedia]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {cameraItems && cameraItems.length > 0 && (
            // <>
            <div className="mb-2">
              <span className="d-block fw-bold mb-1">
                <small>Cameras:</small>
              </span>
              <Select
                inputId="camera-select"
                options={cameraOptions}
                isMulti
                defaultValue={[cameraOptions[0]]} // Select the first option by default
                onChange={handleCameraSelectChange}
                placeholder="Select..."
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    // backgroundColor: "#F5F8FB",
                    borderColor: "#d1d7e0",
                    fontSize: 13,
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    color: "#506690",
                  }),
                  option: (provided: any) => ({
                    ...provided,
                    fontSize: 13,
                  }),
                }}
              />
            </div>
            // </>
          )}

          {cameraItems.length > 0 && selectedItems.length > 0 && (
            <Videos
              selectedCameras={selectedItems}
              groupId={groupId}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Cameras;
