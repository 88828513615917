import { useEffect, useState } from "react";
import BarChart from "./BarChart";
import {
  CHART_BG_COLORS,
  CHART_BORDER_COLORS,
  check_date_within_range,
} from "../../utils/reports";
import {
  IdentifiedObjectItem,
  IdentifiedSpeciesCount,
} from "../../models/Media";
import { CameraItem } from "../../models/Camera";

interface SpeciesBySeasonProps {
  cameras: CameraItem[];
  selectedCameras: CameraItem[];
  allIdentifiedObjects: IdentifiedObjectItem[];
  selectedIdentifiedObjects: IdentifiedObjectItem[];
  reportData: IdentifiedSpeciesCount[];
  startDate: string;
  endDate: string;
}
export const seasons = [
  { name: "Summer", startMonth: 0, endMonth: 2 }, // Summer: December, January, February
  { name: "Autumn", startMonth: 3, endMonth: 5 }, // Autumn: March, April, May
  { name: "Winter", startMonth: 6, endMonth: 8 }, // Winter: June, July, August
  { name: "Spring", startMonth: 9, endMonth: 11 }, // Spring: September, October, November
];
export const getTotalCount = (
  object_name: string,
  seasonIndex: number,
  reportData: IdentifiedSpeciesCount[],
  cameras: CameraItem[],
  startDate: string,
  endDate: string
): number | null => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      for (const camera of cameras) {
        // Check if the date is within the selected range, if provided
        const isWithinDateRange = check_date_within_range(
          startDate,
          endDate,
          data.date
        );

        // Convert the date from the data to a JavaScript Date object
        const dataDate = new Date(data.date);

        // Check if the data date is within the selected season
        const isWithinSeason =
          dataDate.getMonth() >= seasons[seasonIndex].startMonth &&
          dataDate.getMonth() <= seasons[seasonIndex].endMonth;

        if (
          isWithinDateRange &&
          isWithinSeason &&
          camera.camera_id === data.camera
        )
          total_count += data.count;
      }
    }
    return total_count > 0 ? total_count : null;
  }

  return null;
};
export const getCount = (
  object_name: string,
  seasonIndex: number,
  reportData: IdentifiedSpeciesCount[],
  camera: string,
  startDate: string,
  endDate: string
): number => {
  let count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      // Check if the date is within the selected range, if provided
      const isWithinDateRange = check_date_within_range(
        startDate,
        endDate,
        data.date
      );

      // Convert the date from the data to a JavaScript Date object
      const dataDate = new Date(data.date);
      // Check if the data date is within the selected season
      const isWithinSeason =
        dataDate.getMonth() >= seasons[seasonIndex].startMonth &&
        dataDate.getMonth() <= seasons[seasonIndex].endMonth;

      if (isWithinDateRange && isWithinSeason && camera === data.camera)
        count += data.count;
      // }
    }
    return count;
  }

  return 0;
};

const SpeciesBySeasons = ({
  cameras,
  selectedCameras,
  allIdentifiedObjects,
  selectedIdentifiedObjects,
  reportData,
  startDate,
  endDate,
}: SpeciesBySeasonProps) => {
  const [mapData, setMapData] = useState<any>(null);
  const [csvData, setCsvData] = useState<any>();
  const ChartName = "Species Observed by Seasons";

  // Create the customized formatted data for exporting CSV file
  const csvArrays: any = (seasonName: string, seasonIndex: number) =>
    selectedCameras?.flatMap((camera) => {
      return selectedIdentifiedObjects?.map((object) => {
        let seasonObject: any = {
          Camera: camera.camera_id,
          Object: object.name,
          Season: seasonName,
          Count: getCount(
            object.name,
            seasonIndex,
            reportData,
            camera.camera_id,
            startDate,
            endDate
          ),
        };

        return seasonObject;
      });
    });

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    // Load all csv related data
    const csv = seasons.flatMap((seasonName, seasonIndex) =>
      csvArrays(seasonName.name, seasonIndex)
    );

    setCsvData(csv);
  }, [
    selectedCameras,
    selectedIdentifiedObjects,
    startDate,
    endDate,
    reportData,
  ]);

  useEffect(() => {
    if (selectedCameras && selectedIdentifiedObjects) {
      const data = {
        labels: seasons.map((season) => season.name),
        datasets: selectedIdentifiedObjects.map((objectName, index) => ({
          label: objectName.name,
          data: seasons.map((_, seasonIndex) =>
            getTotalCount(
              objectName.name,
              seasonIndex,
              reportData,
              cameras,
              startDate,
              endDate
            )
          ),
          backgroundColor: CHART_BG_COLORS[Object.keys(CHART_BG_COLORS)[index]],
          borderColor:
            CHART_BORDER_COLORS[Object.keys(CHART_BORDER_COLORS)[index]],
          borderWidth: 1,
        })),
      };
      setMapData(data);
    }
  }, [
    selectedCameras,
    selectedIdentifiedObjects,
    reportData,
    startDate,
    endDate,
  ]);
  const getYAxisConfig = (datasets: any[]) => {
    if (!datasets || datasets.length === 0) {
      return { suggestedMax: 10, stepSize: 1 };
    }

    // Extract all count values from datasets
    const allValues = datasets.flatMap((dataset) =>
      dataset.data.map((d: any) => (d !== null && d !== undefined ? d : 0))
    );

    const maxValue = Math.max(...allValues);

    let stepSize = 1;
    let suggestedMax = 10;

    if (maxValue < 10) {
      stepSize = 1;
      suggestedMax = 10;
    } else if (maxValue < 70) {
      stepSize = 10;
      suggestedMax = Math.ceil(maxValue / 10) * 10;
    } else if (maxValue < 350) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else if (maxValue < 1000) {
      stepSize = 50;
      suggestedMax = Math.ceil(maxValue / 50) * 50;
    } else {
      stepSize = 200; // Adjust as needed for larger max values
      suggestedMax = Math.ceil(maxValue / 200) * 200;
    }

    return { suggestedMax, stepSize };
  };

  // Using mapData conditionally to avoid error
  const { suggestedMax, stepSize } = mapData
    ? getYAxisConfig(mapData.datasets)
    : { suggestedMax: 10, stepSize: 1 };
  // Define chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Animals Observed by Season",
        position: "top" as const,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: suggestedMax,
        ticks: {
          stepSize: stepSize,
        },
      },
    },
  };

  return (
    <BarChart
      mapData={mapData}
      options={options}
      csvData={csvData}
      name={ChartName}
    />
  );
};

export default SpeciesBySeasons;
